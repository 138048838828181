import Vue from "vue";
import Vuex from "vuex";
import {Loading_Spinner} from "./storeconstant"
import Account from "./Account";
import opportunity from "./opportunity";
import Youth from "./Youth";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {
  // },
  // getters: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    Account,
    opportunity,
    Youth,
  },
  state(){
    return{
      showLoading:false,
    }
  },
  mutations:{
    Loading_Spinner(state,payload)
    {
      state.showLoading = payload;
    }
  }
});
