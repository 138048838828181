<template>
    <div class="theloader">
        <div class="loading-box">
            <div class="lds-roller">
            <div>
                </div>
                <div>
                    </div>
                    <div>
                        </div>
                        <div>
                            </div>
                            <div>
                                </div>
                                <div>
                                    </div>
                                    <div>
                                        </div>
                                        <div>
                                            </div>
                                            </div>
                                            <div>{{text}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        text:{
            required:false,
            type:String,
            default:"Please wait.."
        }
    }
}
</script>