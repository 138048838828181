import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
    YouthList: [],
  },
  getters: {
    GetYouthList(state) {
      return state.YouthList;
    },
  },
  actions: {
    async GetYouth({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}User/GetYouth/${payload.id}/search?Oid=${payload.OId}&page=${payload.page}&pageSize=${payload.pageSize}&searchString=${payload.searchString}`
        )
        .then((res) => {
          commit("SetYouthList", res.data.data.recordOnPage);
          return res.data.data;
        });
    },
    
    async OrganizationAuthCode({commit},payload){
      console.log("payload",payload);
      return await axios
          .post(`${process.env.VUE_APP_API_URL}Payment/OrganizationAuthCode`,payload)
          .then((res)=>{
            
            return res.data;
          });
    },
    async ToggleOpportunitySelection({ commit }, payload) {
      return await axios
        .put(
          `${process.env.VUE_APP_API_URL}User/ToggleOpportunitySelection/${payload.id}?Status=${payload.status}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async PayYouth({commit},payload){
      return await axios
      .post(`${process.env.VUE_APP_API_URL}Payment/PayYouthById/${payload}`)
      .then((res)=>{
        return res.data;
      });
    },
  },
  mutations: {
    SetYouthList(state, payload) {
      state.YouthList = payload;
    },
  },
};
