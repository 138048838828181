<template>
  <v-app style="background-color: #ebeff2">
    <v-main class="pt-0">
      <loader-component v-if="showLoading"></loader-component>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LoaderComponent from "./components/LoaderComponent.vue"
import { mapGetters, mapState } from "vuex";
export default {
  name: "App",
  components:{LoaderComponent},
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["LoggedInUser"]),
    ...mapState({
      showLoading: (state)=>state.showLoading
    }),
  },
  created(){
    
    //let data = JSON.parse(localStorage.getItem(this.LoggedInUser))
    //localStorage.removeItem("isLoggedIn_SBP");
    //console.log(this.LoggedInUser,"LocalStoreage")
  },
  mounted() {
    
  },
};
</script>
