import Vue from "vue";
import axios from "axios";
import Vuex, { Store } from "vuex";

Vue.use(Vuex);

export default {
  state: {
    organizationData: null,
    LoggedInUser: null,
    IsLoggedIn: false,
    forgotPasswordEmail: null,
    resetPassword: null,
  },
  getters: {
    getOrganizationData(state) {
      return state.organizationData;
    },
    getOrganizationId(state) {
      return state.organizationData;
    },
    LoggedInUser(state) {
      return state.LoggedInUser;
    },
    // getOpportunityData(state) {
    //   return state. OpportunityData.id;
    // },
    IsLoggedIn(state) {
      console.log(localStorage.getItem("isLoggedIn_SBP"));
      if (JSON.parse(localStorage.getItem("isLoggedIn_SBP")) == null) {
        console.log("asdfsdk");
        state.IsLoggedIn = false;
        return state.IsLoggedIn;
      }
      state.LoggedInUser = JSON.parse(localStorage.getItem("isLoggedIn_SBP"));
      state.IsLoggedIn = true;
      console.log("Logged in");
      return state.IsLoggedIn;
    },
    getForgotEmail(state) {
      return state.forgotPasswordEmail;
    },
    getResetPassword(state) {
      return state.resetPassword;
    },
  },
  actions: {
    async Register({ commit }, payload) {
      debugger;
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Account/Register`, payload)
        .then((response) => {
          if (response.data.status) {
            commit("setOrganizationData", response.data.data);
          }
          return response.data;
        });
    },
    async Login({ commit }, payload) {
      debugger;
      
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Account/Login`, payload)
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            commit("SetUser", response.data);
          }
          return response.data;
        });
    },
    async UpdateOrganizationwithCheckoutsession({commit},payload)
    {
      return await axios
             .put(`${process.env.VUE_APP_API_URL}Payment/UpdateOrganizationwithCheckoutsession/${payload.id}/${payload.email}`)
             .then((response)=>{
              return response.data;
             });
    },
    async CheckoutSession({commit},payload){
      return await axios
             .post(`${process.env.VUE_APP_API_URL}Payment/CheckoutSession/${payload}`)
             .then((response)=>{
              return response.data;
             });
    },
    async CreateConnectedAccount({commit},payload)
    {
      var headers = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var formdata = new FormData();
      formdata.append('email',payload.email)
      formdata.append('accountHolder',payload.accountHolder)
      formdata.append('routingNumber',payload.routingNumber)
      formdata.append('accountNumber',payload.accountNumber)
      formdata.append('idNumber',payload.idNumber)
      formdata.append('ssn',payload.ssn)
      formdata.append('streetAddress',payload.streetAddress)
      formdata.append('dob',payload.dob)//BlankCheque
      formdata.append('blankCheque',payload.blankCheque)//BlankCheque
      return await axios
              .post(`${process.env.VUE_APP_API_URL}Payment/CreateConnectedAccount`,formdata,headers)
              .then((response)=>{
                return response.data;
              });
    },
    async ForgotPassword({ commit }, payload) {
      debugger;
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Organization/ForgotPassword/${payload.email}`
        )
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            commit("setForgotPasswordEmail", response.data);
          }
          return response.data;
        });
    },
    async ResetPassword({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Organization/ResetPassword`,
          payload
        )
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            commit("setResetPassword", response.data);
          }
          return response.data;
        });
    },
    async UserResetPassword({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}User/ResetPassword`,
          payload
        )
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            commit("setResetPassword", response.data);
          }
          return response.data;
        });
    },
    async Logout({ commit }, payload) {
      commit("Logout");
      return;
    },
    async addOpportuntiy({ commit }, payload) {
      debugger;
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Opportunity/SelectOpportunityType`,
          payload
        )
        .then((response) => {
          if (response.data.status) {
            commit("setOpportunityData", response.data.data);
          }
          return response.data;
        });
    },
  },
  mutations: {
    setOrganizationData: (state, data) => {
      if (data != null) {
        state.organizationData = data;
      }
    },
    setForgotPasswordEmail(state, data) {
      state.forgotPasswordEmail = data;
    },
    setResetPassword(state, payload) {
      state.resetPassword = payload;
    },
    SetUser(state, payload) {
      console.log(payload);
      localStorage.setItem("isLoggedIn_SBP", JSON.stringify(payload.data));
      state.LoggedInUser = payload.data;
      state.IsLoggedIn = true;
    },
    Logout(state, payload) {
      console.log("Logged Out");
      state.LoggedInUser = null;
      state.IsLoggedIn = false;
      localStorage.removeItem("isLoggedIn_SBP");
    },
  },
};
