import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../views/Main/HomePage.vue"),
    children: [
      {
        path: "/homepages",
        name: "homepages",
        component: () => import("../views/Homepage/Homepage.vue"),
      },
      {
        path: "/youth",
        name: "youthHome",
        component: () => import("../views/Youth/youth.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/Login.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/Auth/Register.vue"),
      },
      {
        path: "/forgot",
        name: "forgotPassword",
        component: () => import("../views/Auth/ForgotPassword.vue"),
      },
      {
        path: "/reset/reset-password/",
        name: "resetPassword",
        component: () => import("../views/Auth/ResetPassword.vue"),
        beforeEnter: (to, from, next) => {
          if (to.query.uid && to.query.token) {
            next();
          } else {
            next('/login');
          }
        },
      },
      {
        path: "/reset/reset-user-password/",
        name: "resetUserPassword",
        component: () => import("../views/Auth/ResetUserPassword.vue"),
      },
      {
        path: "/confirmEmail",
        name: "confirmEmail",
        component: () => import("../views/Auth/EmailConfirm.vue"),
      },
      {
        path: "/privacypolicy",
        name: "privacypolicy",
        component: () => import("../views/Homepage/privacyPolicy.vue"),
      },
    ],
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Main/dashboardlayout.vue"),

    children: [
      // {
      //   path: "/resource",
      //   component: () => import("../views/dashboardviews/dashboardresources.vue"),
      // },
      // {
      //   path: "/company",
      //   component: () => import("../views/dashboardviews/dashboardcompanies.vue"),
      // },
      {
        path: "/opportunity",
        name: "opportunity",
        component: () =>
          import("../views/dashboardviews/dashboardopportunity.vue"),
      },
      {
        path: "/myopportunity",
        name: "myopportunity",
        component: () =>
          import("../views/dashboardviews/createopportunity.vue"),
      },
      {
        path: "/createopportunity",
        name: "createopportunity",
        component: () => import("../views/dashboardviews/myopportunity.vue"),
      },
      {
        path: "/opportunitycard",
        name: "opportunitycard",
        component: () => import("../views/dashboardviews/opporunitycard.vue"),
      },
      {
        path: "/searchyouth/:id?",
        name: "searchyouth",
        component: () => import("../views/dashboardviews/searchyouth.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    if (to.query !== null) {
      if (to.query.code !== undefined) {
        localStorage.setItem('paypalCode', to.query.code);
      }
    }
  }
  if (store.getters["IsLoggedIn"]) {
    if (Object.keys(from.params).length === 2) {
      next();
    }
    else if (
      to.name == "login" ||
      to.name == "HomePage" ||
      to.name == "register" ||
      to.name == "youthHome" ||
      to.name == "homepages" ||
      to.name == "forgotPassword" ||
      to.name == "resetPassword" ||
      to.name == "resetUserPassword" ||
      to.name == "confirmEmail" ||
      to.name == "privacypolicy"
    ) {
      next({ name: "opportunity" });
    } else {
      next();
    }
  } else {
    if (
      to.name == "login" ||
      to.name == "register" ||
      to.name == "youthHome" ||
      to.name == "homepages" ||
      to.name == "forgotPassword" ||
      to.name == "resetPassword" ||
      to.name == "resetUserPassword" ||
      to.name == "confirmEmail" ||
      to.name == "privacypolicy"
    ) {
      next();
    } else {
      next({ name: "homepages" });
    }
  }
});
export default router;
