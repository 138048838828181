import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  state: {
    OpportunityData: [],
    ViewOpportunity: null,
  },
  getters: {
    getOpportunityData(state) {
      return state.OpportunityData;
    },
    getViewOpportunity(state) {
      return state.ViewOpportunity;
    },
  },
  actions: {
    async AddOpertunity({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Opportunity/AddOpportunity`,
          payload
        )
        .then((res) => {
          return res.data;
        });
    },
    async GetOpportunitiesById({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Opportunity/GetOpportunityByOrganizationId/${payload}`
        )
        .then((res) => {
          commit("setOpportunityData", res.data.data);
          return res.data;
        });
    },
    async GetOpportunityById({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Opportunity/GetOpportunityByOpportunityId/${payload}`
        )
        .then((res) => {
          commit("setViewOpportunity", res.data.data);
          return res.data;
        });
    },
    async DeleteOpportunity({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Opportunity/DelOpportunityById/${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    setOpportunityData: (state, data) => {
      state.OpportunityData = data;
    },
    setViewOpportunity(state, data) {
      state.ViewOpportunity = data;
    },
  },
};
